import { useEffect } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { isApolloError } from 'apollo-client'
import gql from 'graphql-tag'

import { parseUrlQuery } from '../Router/query'
import { NetworkMessage } from './__generated__/NetworkMessage'

export const NETWORK_MESSAGE = gql`
  query NetworkMessage {
    currentNetworkState @client {
      code
      error
      message
      reason
    }
  }
`

export const interpretErrorMessage = (message: string) => {
  if (message.includes('Failed to fetch')) {
    return 'Network connection failed'
  }
  return message
}

export const processErrors = (err?: any): NetworkMessage => {
  if (!err) {
    return {
      currentNetworkState: {
        __typename: 'DisplayMessage',
        code: null,
        error: null,
        message: '',
        reason: null,
      },
    }
  }
  let message: string
  if (isApolloError(err)) {
    if (err?.graphQLErrors?.length) {
      message = err.graphQLErrors.map(e => e.message).join(', ')
    } else {
      message = err?.networkError?.message ?? 'Network error'
    }
  } else {
    message = err.message
  }

  message = interpretErrorMessage(message)

  return {
    currentNetworkState: {
      __typename: 'DisplayMessage',
      code: null,
      error: err.name ?? null,
      message,
      reason: null,
    },
  }
}

export const SESSION_TIMEOUT_MESSAGE = 'Login timed out'

// When redirected from OAuth callback, the errors are in the query params
export const useNetworkStateFromUrlParam = () => {
  const client = useApolloClient()
  useEffect(() => {
    const { code, error, message, reason } = parseUrlQuery(window.location.href)
    client.writeQuery({
      query: NETWORK_MESSAGE,
      data: {
        currentNetworkState: {
          __typename: 'DisplayMessage',
          code: code ?? null,
          error: error ?? null,
          message: message ?? null,
          reason: reason ?? null,
        },
      },
    })
  }, [])
}

export const useNetwork = () => {
  const client = useApolloClient()
  return {
    clearNetwork: () => {
      client.writeQuery<NetworkMessage>({
        query: NETWORK_MESSAGE,
        data: processErrors(),
      })
    },
  }
}
