import customTheme from '../custom-theme.json'

export const BLACK_COLOR = '#000'
export const PRIMARY_COLOR = customTheme['color-primary-500']
export const CONTRAST_COLOR = customTheme['color-primary-100']
export const INFO_COLOR = customTheme['color-info-500'] // This is the logo color
export const SUCCESS_COLOR = customTheme['color-success-500']
export const WARNING_COLOR = customTheme['color-warning-500']
export const ERROR_COLOR = customTheme['color-danger-500']
export const FADED = 'rgba(0, 0, 0, 0.54)'
export const FADED_GREY = 'rgba(64, 64, 64, 0.3)'
export const GREY = 'rgb(75,75,75)'

// social media colors
export const FACEBOOK_PRIMARY_COLOR = '#4166b1'
export const INSTAGRAM_PRIMARY_COLOR = '#dd2a7b'
export const LINKEDIN_PRIMARY_COLOR = '#0e76a8'
export const TWITTER_PRIMARY_COLOR = '#00acee'
