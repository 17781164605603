import { joinPaths } from '@binary-owls/sdk-node'
import React from 'react'

import { useHistory, useRouteMatch, Switch, Route } from '../Router'
import { CreatePhoto } from './CreatePhoto'
import { Photos } from './Photos'
import { Heading } from '../Components/Heading'
import { SelectPhotoHistoryState } from './types'
import { Button } from '@ui-kitten/components'

interface Props {
  photoLimit?: number
}

export const Album: React.FC<Props> = ({ photoLimit }) => {
  const history = useHistory<SelectPhotoHistoryState>()

  const { path } = useRouteMatch()
  const uploadPath = joinPaths(path, 'upload')

  const photosProps = {
    choosePhoto: history.location.state?.returnPath ? () => history.goBack() : undefined,
  }

  return (
    <Switch>
      <Route exact path={uploadPath}>
        <CreatePhoto done={() => history.goBack()} />
      </Route>
      <Route exact path={path}>
        <Heading title="My Photos">
          <Button status="success" onPress={() => history.push(uploadPath)}>
            Add Photo
          </Button>
        </Heading>
        <Photos {...photosProps} />
      </Route>
    </Switch>
  )
}
