import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Card, Layout, Text } from '@ui-kitten/components'
import gql from 'graphql-tag'
import * as React from 'react'
import { Dimensions, StyleSheet } from 'react-native'

import Loading from '../Loading'
import * as GetPostTemplatesTypes from './__generated__/GetPostTemplates'
import { ContactEmail } from '../Notifications/ContactEmail'
import * as UpdateCurrentPost from './__generated__/UpdateCurrentPost'
import ErrorDisplay from '../Notifications/ErrorDisplay'
import { GET_CURRENT_POST } from '../state/resolvers'
import { ChevronDownIcon } from '../Icons'
import { PORTRAIT_WIDTH } from '../styles'
import { initializePostInput, UPDATE_CURRENT_POST } from './gql'

const LIMIT = 3

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  text: {
    maxWidth: '80%',
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    marginVertical: 30,
  },
  templates: {
    maxWidth: PORTRAIT_WIDTH * (1.1 * LIMIT),
    margin: 30,
    alignSelf: 'stretch',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  template: {
    width: PORTRAIT_WIDTH,
    minHeight: 120,
    margin: 5,
  },
  row: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  middle: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  right: {
    flexGrow: 0,
  },
  prompt: {
    marginRight: 10,
  },
  otherActions: {
    marginTop: 20,
    marginRight: 30,
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})

const GET_POST_TEMPLATES = gql`
  query GetPostTemplates($except: [ID!], $first: Int!) {
    postTemplates(except: $except, first: $first) {
      edges {
        node {
          id
          content
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`

interface Props {
  done: () => void
}

export const CreatePost: React.FC<Props> = ({ done }) => {
  const [mutate, { error, loading: loadingMutation }] = useMutation<
    UpdateCurrentPost.UpdateCurrentPost,
    UpdateCurrentPost.UpdateCurrentPostVariables
  >(UPDATE_CURRENT_POST, {
    refetchQueries: [{ query: GET_CURRENT_POST }],
  })

  const limit = LIMIT * (Dimensions.get('window').height > 800 ? 2 : 1)

  const { data, error: templatesError, fetchMore, loading } = useQuery<
    GetPostTemplatesTypes.GetPostTemplates,
    GetPostTemplatesTypes.GetPostTemplatesVariables
  >(GET_POST_TEMPLATES, {
    errorPolicy: 'all',
    variables: {
      except: [],
      first: limit,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError(err) {},
  })

  const items = data?.postTemplates?.edges

  return (
    <Layout style={styles.container}>
      <Text category="h4" style={styles.text}>
        Choose a post template
      </Text>
      <ErrorDisplay error={error} />
      <ErrorDisplay error={templatesError} />
      <Text style={styles.text}>We thought of some pre-written posts that you can use!</Text>
      {items?.length ? (
        <Layout style={styles.templates}>
          {items.map(({ node: { id, content } }) => (
            <Card
              style={styles.template}
              status="primary"
              key={`template-${id}`}
              disabled={loadingMutation}
              onPress={() => {
                const postInput = initializePostInput({ content, postTemplateId: id })
                return mutate({ variables: { input: postInput } }).then(done)
              }}
            >
              <Text>{content}</Text>
            </Card>
          ))}
        </Layout>
      ) : (
        <Text>
          No post templates found. Please contact <ContactEmail />
        </Text>
      )}
      <Layout style={styles.row}>
        <Layout style={styles.middle}>
          <Loading loading={loading}>
            {data?.postTemplates?.pageInfo.hasNextPage && (
              <Button
                appearance="outline"
                status="info"
                icon={ChevronDownIcon}
                onPress={() =>
                  fetchMore({
                    variables: {
                      except: data?.postTemplates?.edges?.map(({ node }) => node.id),
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      const newEdges = fetchMoreResult?.postTemplates?.edges
                      const pageInfo = fetchMoreResult?.postTemplates?.pageInfo

                      return newEdges?.length
                        ? ({
                            // Put the new comments at the end of the list and update `pageInfo`
                            postTemplates: {
                              __typename: 'PostTemplateConnection',
                              edges: [...(previousResult?.postTemplates?.edges ?? []), ...newEdges],
                              pageInfo,
                              totalCount: fetchMoreResult?.postTemplates?.totalCount,
                            },
                          } as GetPostTemplatesTypes.GetPostTemplates)
                        : previousResult
                    },
                  }).catch(err => console.info(`fetchMore erred ${err}`))
                }
              >
                More
              </Button>
            )}
          </Loading>
        </Layout>
      </Layout>
      <Layout style={styles.otherActions}>
        <Text style={styles.prompt}>Don't need a template?</Text>
        <Button
          appearance="outline"
          status="info"
          onPress={() => {
            const postInput = initializePostInput()
            return mutate({ variables: { input: postInput } }).then(done)
          }}
        >
          Create Blank Post
        </Button>
      </Layout>
    </Layout>
  )
}
