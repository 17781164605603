import { Layout, Button, Card, Icon, Input, Tab, TabBar, Text } from '@ui-kitten/components'
import { useMutation } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { ImageStyle, StyleSheet, TouchableOpacity, View } from 'react-native'

import * as LoginTypes from './__generated__/LogInUser'
import * as RegisterTypes from './__generated__/Register'
import Loading from '../Loading'
import { SOCIAL_MEDIA_INTEGRATIONS } from '../SocialMedia/socialMedia'
import LoginButton from './LoginButton'
import useCurrentUser from '../state/useCurrentUser'
import gql from 'graphql-tag'
import GlobalError from '../Notifications/GlobalError'
import { parseUrlQuery } from '../Router/query'
import { useNetwork } from '../state/useNetwork'
import { PRIMARY_COLOR } from '../styles/colors'
import { isDevelopmentMode } from '../dev/devMode'
import { PORTRAIT_WIDTH } from '../styles'

const styles = StyleSheet.create({
  card: {
    cursor: 'default',
    margin: 30,
    padding: 20,
    flex: 1,
    maxWidth: PORTRAIT_WIDTH,
    minHeight: 350,
    alignItems: 'center',
  },
  tab: {
    marginTop: 20,
  },
  input: {
    marginTop: 5,
    marginBottom: 3,
  },
  button: {
    marginTop: 20,
    marginBottom: 5,
  },
  text: {
    margin: 30,
    textAlign: 'center',
  },
  center: {
    alignItems: 'center',
  },
  prompt: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  promptText: {
    color: PRIMARY_COLOR,
    fontSize: 12,
  },
  promptAction: {
    fontWeight: 'bold',
    marginLeft: 5,
  },
})

export const LOG_IN_USER = gql`
  mutation LogInUser($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      name
      username
      facebookId
      facebookEmail
      facebookShortName
      facebookName
    }
  }
`

export const REGISTER_USER = gql`
  mutation Register($email: String!, $password: String!) {
    register(email: $email, password: $password) {
      email
      name
      username
      facebookId
      facebookEmail
      facebookShortName
      facebookName
    }
  }
`

const LoginForm = () => {
  const [registering, setRegistering] = useState('register' in parseUrlQuery(window.location.href))
  const { clearNetwork } = useNetwork()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setUser } = useCurrentUser()
  const [login, { loading: loadingLogin }] = useMutation<LoginTypes.LogInUser, LoginTypes.LogInUserVariables>(
    LOG_IN_USER,
    {
      errorPolicy: 'all',
      // awaitRefetchQueries: true,
      // refetchQueries: [{ query: GET_LOGGED_IN_USER }],
      onError() {},
      onCompleted({ login }) {
        if (login) {
          setUser({
            email: login.email,
            name: login.name,
            username: login.username,
            facebookEmail: login.facebookEmail,
            facebookShortName: login.facebookShortName,
            facebookName: login.facebookName,
          })
        }
      },
    },
  )

  const [register, { loading: loadingRegistery }] = useMutation<
    RegisterTypes.Register,
    RegisterTypes.RegisterVariables
  >(REGISTER_USER, {
    onError() {},
    onCompleted({ register }) {
      if (register) {
        setUser({
          email: register.email,
          name: register.name,
          username: register.username,
          facebookEmail: register.facebookEmail,
          facebookShortName: register.facebookShortName,
          facebookName: register.facebookName,
        })
      }
    },
  })

  const loading = loadingLogin || loadingRegistery
  const onLogin = () => {
    setEmail(email.toLowerCase())
    login({ variables: { email, password } })
  }
  const onRegister = () => {
    setEmail(email.toLowerCase())
    register({ variables: { email, password } })
  }

  // Toggle obfuscating of password
  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const onIconPress = () => {
    setSecureTextEntry(!secureTextEntry)
  }
  const renderIcon = (style: ImageStyle) => <Icon {...style} name={secureTextEntry ? 'eye-off' : 'eye'} />

  const smLogins = !isDevelopmentMode() ? null : (
    <>
      <Text style={styles.text} category="label">
        or
      </Text>
      {SOCIAL_MEDIA_INTEGRATIONS.map(socialMedia => (
        <LoginButton
          disabled={loading}
          key={`login-button-${socialMedia}`}
          style={styles.button}
          socialMedia={socialMedia}
        />
      ))}
    </>
  )

  return (
    <Card style={styles.card}>
      <TabBar
        selectedIndex={registering ? 1 : 0}
        onSelect={tabIndex => {
          clearNetwork()
          setRegistering(tabIndex === 1)
        }}
      >
        <Tab title="Log In" />
        <Tab title="Register" />
      </TabBar>
      {registering ? (
        <Layout style={styles.tab}>
          <Input
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            label="Email"
            style={styles.input}
            autoFocus={!email}
            onChangeText={setEmail}
            onSubmitEditing={onRegister}
            value={email}
          />
          <Input
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            label="Password"
            style={styles.input}
            autoFocus={!!email}
            onChangeText={setPassword}
            onSubmitEditing={onRegister}
            value={password}
            icon={renderIcon}
            onIconPress={onIconPress}
            secureTextEntry={secureTextEntry}
          />
          <Button style={styles.button} disabled={!email || !password || loading} onPress={onRegister}>
            Register
          </Button>
          <Layout style={styles.prompt}>
            <Text style={styles.promptText}>Have an account?</Text>
            <TouchableOpacity disabled={loading} onPress={() => setRegistering(false)}>
              <Text style={[styles.promptText, styles.promptAction]}>Log in</Text>
            </TouchableOpacity>
          </Layout>
          {smLogins}
        </Layout>
      ) : (
        <Layout style={styles.tab}>
          <Input
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            label="Email"
            style={styles.input}
            autoFocus={!email}
            onChangeText={setEmail}
            onSubmitEditing={onLogin}
            value={email}
          />
          <Input
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            label="Password"
            style={styles.input}
            autoFocus={!!email}
            onChangeText={setPassword}
            onSubmitEditing={onLogin}
            value={password}
            icon={renderIcon}
            onIconPress={onIconPress}
            secureTextEntry={secureTextEntry}
          />
          <Button style={styles.button} disabled={!email || !password || loading} onPress={onLogin}>
            Log In
          </Button>
          <Layout style={styles.prompt}>
            <Text style={styles.promptText}>No account?</Text>
            <TouchableOpacity disabled={loading} onPress={() => setRegistering(true)}>
              <Text style={[styles.promptText, styles.promptAction]}>Create one</Text>
            </TouchableOpacity>
          </Layout>
          {smLogins}
        </Layout>
      )}
      <Loading loading={loading} />
      <View style={styles.center}>
        <GlobalError />
      </View>
    </Card>
  )
}
export default LoginForm
