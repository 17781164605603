import gql from 'graphql-tag'

// User from server
export const GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser {
    me {
      id
      email
      name
      username
      facebookId
      facebookEmail
      facebookShortName
      facebookName
    }
  }
`

// Cached user
export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser @client {
      email
      name
      username
      facebookEmail
      facebookShortName
      facebookName
    }
  }
`

export const LOG_OUT_USER = gql`
  mutation LogOut {
    logout
  }
`
