import { Icon } from '@ui-kitten/components'
import React from 'react'
import { GestureResponderEvent, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'

import { INFO_COLOR } from '../styles/colors'

interface Props {
  color?: string
  href: string
  icon: string
  isButton?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: (event: GestureResponderEvent) => void
}

export const ExternalLink: React.FC<Props> = ({
  children,
  color = INFO_COLOR,
  href,
  icon,
  isButton = false,
  onPress,
  style,
}) => {
  const body = (
    <a target="_blank" rel="noreferrer noopener nofollow" href={href}>
      {!isButton ? (
        children
      ) : (
        <View style={[{ borderColor: color }, styles.button]}>
          <Icon fill={color} style={styles.icon} name={icon} />
          <Text style={{ color, fontWeight: 'bold' }}>{children}</Text>
        </View>
      )}
    </a>
  )
  if (onPress) {
    return <TouchableOpacity onPress={onPress} style={style}></TouchableOpacity>
  }
  return <View style={style}>{body}</View>
}

export const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  icon: {
    width: 25,
    height: 25,
    marginRight: 5,
  },
})
