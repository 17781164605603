import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Button } from '@ui-kitten/components'

import { useHistory } from '../Router'
import { PRIMARY_COLOR } from '../styles/colors'
import { GET_CURRENT_USER } from '../Users/gql'
import { GetCurrentUser } from '../Users/__generated__/GetCurrentUser'
import Loading from '../Loading'
import { useMediaQueries } from '../utils/useScreen'
import { QuestionIcon, UserIcon } from '../Icons'
import { Logo } from './Logo'
import { ContactEmail } from '../Notifications/ContactEmail'
import { ContactPhone } from '../Notifications/ContactPhone'
import { ContactSMS } from '../Notifications/ContactSMS'

const styles = StyleSheet.create({
  helpBar: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 10,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    width: '100%',
    padding: 10,
  },
  left: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  right: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    marginLeft: 10,
  },
  buttonText: {
    color: PRIMARY_COLOR,
    textTransform: 'uppercase',
  },
  home: {
    fontWeight: 'bold',
  },
})

export const NavigationBar: React.FC = () => {
  const history = useHistory()
  const { isSmallScreen } = useMediaQueries()
  const { data, loading: loadingQuery } = useQuery<GetCurrentUser>(GET_CURRENT_USER, {
    onError() {
      // Top level query should catch if user is not logged in
    },
  })

  const [showHelp, setShowHelp] = useState(false)

  const loading = loadingQuery
  if (loading) {
    return <Loading splash />
  }

  let myself
  if (data) {
    const myName =
      (!isSmallScreen &&
        (data.currentUser?.name ||
          data.currentUser?.facebookShortName ||
          data.currentUser?.facebookName ||
          data.currentUser?.email ||
          data.currentUser?.facebookEmail)) ||
      ''
    myself = (
      <Button
        disabled={loading}
        onPress={() => history.push('/me')}
        appearance={isSmallScreen ? 'ghost' : 'outline'}
        icon={UserIcon}
      >
        {myName}
      </Button>
    )
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.left}>
          <TouchableOpacity onPress={() => history.push('/')}>
            <Logo />
          </TouchableOpacity>
        </View>
        <View style={styles.right}>
          {myself}
          <Button
            style={{ marginLeft: 5 }}
            appearance={isSmallScreen ? 'ghost' : 'outline'}
            icon={QuestionIcon}
            onPress={() => setShowHelp(() => !showHelp)}
          >
            {isSmallScreen ? '' : 'Questions'}
          </Button>
        </View>
      </View>
      {showHelp && (
        <View style={styles.helpBar}>
          <ContactSMS isButton />
          <ContactPhone isButton />
          <ContactEmail isButton />
        </View>
      )}
    </>
  )
}
