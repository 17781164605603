import gql from 'graphql-tag'

export const typeDefs = gql`
  type UnsavedPost {
    content: String!
    draft: Boolean!
    postedFacebook: Boolean!
    postedInstagram: Boolean!
    postedLinkedin: Boolean!
    postedTwitter: Boolean!
    postTemplateId: ID
    fileId: ID
  }

  extend type Query {
    currentPost: UnsavedPost
    currentUser: User
    currentNetworkState: DisplayMessage!
  }

  extend type Mutation {
    updateCurrentPost(input: PostInput!): UnsavedPost
  }

  type DisplayMessage {
    code: Int
    error: String
    message: String!
    reason: String
  }
`
