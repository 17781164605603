import React from 'react'
import { ApolloError, isApolloError } from 'apollo-client'

import ErrorText from './ErrorText'
import { interpretErrorMessage } from '../state/useNetwork'

interface Props {
  error?: ApolloError | Error
}

const ErrorDisplay: React.FC<Props> = ({ error }) => {
  if (error) {
    if (isApolloError(error)) {
      if (error.networkError?.message) {
        return <ErrorText>{interpretErrorMessage(error.networkError.message)}</ErrorText>
      }
      return (
        <>
          {error.graphQLErrors.map(({ message }, index) => (
            <ErrorText key={`error-${index}`}>{message}</ErrorText>
          ))}
        </>
      )
    }
    return <ErrorText>{error}</ErrorText>
  }
  return null
}

export default ErrorDisplay
