import { useQuery } from '@apollo/react-hooks'
import { ApolloError } from 'apollo-client'
import React from 'react'
import { View } from 'react-native'

import ErrorDisplay from './ErrorDisplay'
import ErrorText from './ErrorText'
import { NETWORK_MESSAGE } from '../state/useNetwork'
import * as NetworkMessageTypes from '../state/__generated__/NetworkMessage'

interface Props {
  error?: ApolloError | Error
}

const GlobalError: React.FC<Props> = ({ error }) => {
  const { data } = useQuery<NetworkMessageTypes.NetworkMessage>(NETWORK_MESSAGE)
  let body
  if (error) {
    body = <ErrorDisplay error={error} />
  } else if (data?.currentNetworkState?.message) {
    body = <ErrorText>{data?.currentNetworkState?.message}</ErrorText>
  } else {
    return null
  }
  return <View style={{ marginTop: 10, marginBottom: 5 }}>{body}</View>
}

export default GlobalError
