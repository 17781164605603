import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Layout, Text } from '@ui-kitten/components'
import React, { useState } from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'

import Loading from '../Loading'
import * as GetFiles from './__generated__/GetFiles'
import { DELETE_PHOTO, GET_FILES } from './gql'
import { BLACK_COLOR } from '../styles/colors'
import { ConfirmButton } from '../UI/ConfirmButton'
import { useHistory } from '../Router'
import * as UpdateCurrentPost from '../Posts/__generated__/UpdateCurrentPost'
import { initializePostInput, UPDATE_CURRENT_POST } from '../Posts/gql'
import { GET_CURRENT_POST } from '../state/resolvers'
import * as DeletePhoto from './__generated__/DeletePhoto'

interface Props {
  choosePhoto?: () => any
  limit?: number
}

export const Photos: React.FC<Props> = ({ choosePhoto, limit = 3 }) => {
  const history = useHistory()

  const [selected, setSelected] = useState(-1)
  const { data, loading } = useQuery<GetFiles.GetFiles, GetFiles.GetFilesVariables>(GET_FILES, {
    variables: {
      limit,
    },
    onError() {},
  })
  const [updateCurrentPost, { loading: mutating }] = useMutation<
    UpdateCurrentPost.UpdateCurrentPost,
    UpdateCurrentPost.UpdateCurrentPostVariables
  >(UPDATE_CURRENT_POST, {
    refetchQueries: [{ query: GET_CURRENT_POST }],
  })
  const [deletePhoto, { loading: isDeleting }] = useMutation<DeletePhoto.DeletePhoto, DeletePhoto.DeletePhotoVariables>(
    DELETE_PHOTO,
    {
      refetchQueries: [{ query: GET_FILES }],
    },
  )

  if (loading) {
    return <Loading splash />
  }

  if (!data?.myFiles.length) {
    return (
      <Layout>
        <Text style={{ marginBottom: 20 }}>Start my photo album</Text>
        <ConfirmButton style={{ width: 140 }} title="Add Photo" onPress={() => history.push('/album/upload')} />
      </Layout>
    )
  }

  return (
    <Layout style={styles.collection}>
      {data.myFiles.map((file, i) => {
        return (
          <Layout style={[styles.photoLayout, styles.shadowBorders]} key={`file-${file.id}`}>
            <TouchableOpacity onPress={() => setSelected(selected === i ? -1 : i)}>
              <Image style={styles.photoImage} source={{ uri: file.location }} />
            </TouchableOpacity>
            {selected === i && (
              <Layout style={styles.actions}>
                <Button
                  disabled={mutating || isDeleting}
                  style={styles.button}
                  status="success"
                  onPress={() => {
                    updateCurrentPost({
                      variables: {
                        input: initializePostInput({
                          fileId: file.id,
                        }),
                      },
                    }).then(() => {
                      if (choosePhoto) {
                        choosePhoto()
                      } else {
                        history.push('/posts/new')
                      }
                    })
                  }}
                >
                  {choosePhoto ? 'Select' : 'Post'}
                </Button>
                <Button
                  disabled={mutating || isDeleting}
                  style={styles.button}
                  status="danger"
                  onPress={() => deletePhoto({ variables: { id: file.id } })}
                >
                  Delete
                </Button>
              </Layout>
            )}
          </Layout>
        )
      })}
    </Layout>
  )
}

const styles = StyleSheet.create({
  collection: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  photoLayout: {
    marginRight: 20,
    marginBottom: 20,
  },
  photoImage: {
    width: 220,
    height: 220,
  },
  shadowBorders: {
    shadowColor: BLACK_COLOR,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.39,
    shadowRadius: 8.3,
    elevation: 13,
  },
  actions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 10,
  },
  button: {
    width: 90,
    height: 20,
  },
})
