import React from 'react'

import { CONTACT_EMAIL } from '../config'
import { ExternalLink } from '../Components/ExternalLink'
import { contactStyles } from './contact'

export interface ContactEmailProps {
  isButton?: boolean
}

export const ContactEmail: React.FC<ContactEmailProps> = ({ isButton = false }) => {
  return (
    <ExternalLink
      style={contactStyles.button}
      href={`mailto:${CONTACT_EMAIL}?subject=User Support`}
      icon="email-outline"
      isButton={isButton}
    >
      {CONTACT_EMAIL}
    </ExternalLink>
  )
}
