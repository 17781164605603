import { useApolloClient } from '@apollo/react-hooks'

import { removeStored, setStored } from './clientStorage'
import { GET_CURRENT_USER } from '../Users/gql'
import { GetCurrentUser, GetCurrentUser_currentUser } from '../Users/__generated__/GetCurrentUser'

const USER_KEY = 'user'

export type CurrentUserProps = Omit<GetCurrentUser_currentUser, '__typename'>

export const loadUser = () => {
  try {
    const data = localStorage.getItem(USER_KEY)
    if (data) {
      return {
        currentUser: JSON.parse(data),
      }
    }
  } catch {}
  return null
}

export default function useCurrentUser() {
  const client = useApolloClient()

  return {
    clearUser: () => {
      removeStored(USER_KEY)
      client.writeQuery<GetCurrentUser>({
        query: GET_CURRENT_USER,
        data: { currentUser: null },
      })
    },
    setUser: (currentUserProps: CurrentUserProps) => {
      setStored(USER_KEY, JSON.stringify(currentUserProps))
      client.writeQuery<GetCurrentUser>({
        query: GET_CURRENT_USER,
        data: {
          currentUser: {
            ...currentUserProps,
            __typename: 'User',
          },
        },
      })
    },
  }
}
