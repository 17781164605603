import React, { useState } from 'react'
import { Button, ButtonProps } from '@ui-kitten/components'
import { getColor, getIcon, getTitle } from '../SocialMedia/socialMedia'
import { loginWithSocialMedia } from '../common/auth'
import { useApolloClient } from '@apollo/react-hooks'
import { NetworkMessage } from '../state/__generated__/NetworkMessage'
import { processErrors } from '../state/useNetwork'

interface Props extends ButtonProps {
  socialMedia: string
}

const LoginButton: React.FC<Props> = ({ socialMedia, disabled, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()
  const onPress = () => {
    setLoading(true)
    loginWithSocialMedia(socialMedia).catch(err => {
      client.writeData<NetworkMessage>({
        data: processErrors(err),
      })
      setLoading(false)
    })
  }
  return (
    <Button
      {...rest}
      style={{ backgroundColor: getColor(socialMedia), borderWidth: 0 }}
      onPress={onPress}
      icon={getIcon(socialMedia)}
      disabled={loading || disabled}
    >
      {`Log in with ${getTitle(socialMedia)}`}
    </Button>
  )
}

export default LoginButton
