import React from 'react'
import { useNotifications } from './useNotifications'
import { Card, Layout } from '@ui-kitten/components'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { CloseIcon } from '../Icons'

export const NotificationsHub: React.FC = () => {
  const { confirm, unseen } = useNotifications()

  return (
    <Layout style={styles.container}>
      {unseen.map((notification, index) => {
        return (
          <Card style={styles.card} key={`notification-${index}`}>
            <Layout style={styles.row}>
              <Text style={styles.text}>{notification.text}</Text>
              <TouchableOpacity onPress={() => confirm()}>
                <CloseIcon width={20} height={20} />
              </TouchableOpacity>
            </Layout>
          </Card>
        )
      })}
    </Layout>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 30,
  },
  card: {
    cursor: 'default',
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  text: {
    marginRight: 20,
    textAlign: 'justify',
  },
})
