import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { ERROR_COLOR } from '../styles/colors'

const styles = StyleSheet.create({
  error: {
    color: ERROR_COLOR,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
})

const ErrorText: React.FC = ({ children }) => {
  if (!children) {
    return null
  }
  return <Text style={styles.error}>{children}</Text>
}

export default ErrorText
