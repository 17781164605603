import React from 'react'
import { getImageUrl } from '../utils/image'
import { Image } from 'react-native'

interface Props {
  large?: boolean
}

export const Logo: React.FC<Props> = ({ large = false }) => {
  const styles = {
    width: 598 / (large ? 2 : 6),
    height: 96 / (large ? 2 : 6),
  }

  return <Image style={styles} source={{ uri: getImageUrl('static/images/priffly-long-black.png') }} />
}
