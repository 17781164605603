import { Button } from '@ui-kitten/components'
import React from 'react'
import { Linking, NativeSyntheticEvent, NativeTouchEvent, Platform, StyleProp, ViewStyle } from 'react-native'
import { FADED_GREY } from '../styles/colors'
import { openExternalLink } from '../common/linking'

export interface ShareButtonProps {
  onPress?: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void
  disabled?: boolean
  posted?: boolean
  iconOnly?: boolean
  style?: StyleProp<ViewStyle>
  title?: string
  url?: string
}

export interface GenericShareButtonProps extends ShareButtonProps {
  color: string
  icon?: any
}

const ShareButton: React.FC<GenericShareButtonProps> = ({
  posted,
  disabled = false,
  icon,
  iconOnly,
  url,
  title,
  color,
  onPress,
  style,
}) => {
  const open =
    onPress ??
    (() => {
      if (!url) {
        console.warn('url is missing')
        return
      } else if (Platform.OS !== 'web') {
        return openExternalLink(url)
      }
    })

  const bg = { backgroundColor: posted || disabled ? FADED_GREY : color }
  const styles: StyleProp<ViewStyle> = [{ ...bg, borderWidth: 0, margin: 2 } as ViewStyle]
  if (style) {
    // @ts-ignore
    styles.push(style)
  }

  const button = (
    <Button
      textStyle={{
        fontSize: 20,
        fontWeight: 'bold',
        // textDecorationLine: disabled ? 'line-through' : 'none',
      }}
      disabled={disabled} // TODO: user can still click on this, in case they made a mistake
      style={styles}
      onPress={open}
      icon={icon}
    >
      {icon && iconOnly ? undefined : title}
    </Button>
  )
  if (Platform.OS === 'web') {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {button}
      </a>
    )
  }
  return button
}

export default ShareButton
