import { useState } from 'react'

import { getStored, setStored } from './clientStorage'

const KEY = 'notified-beginner-about-sm-account-setup'

export const useFreshUser = () => {
  const [fresh, setFresh] = useState(() => 'true' !== getStored(KEY))

  const toggleOffFreshUser = () => {
    setStored(KEY, JSON.stringify(fresh))
    setFresh(() => !fresh)
  }

  return {
    isFreshUser: fresh,
    toggleOffFreshUser,
  }
}
