import { Linking } from 'react-native'

export const openExternalLink = (url: string) => {
  return Linking.canOpenURL(url)
    .then(isSupported => {
      if (isSupported) {
        return Linking.openURL(url)
      } else {
        console.error("Can't handle URL: " + url)
      }
    })
    .catch(err => console.error(`An error occurred opening URL ${url}`, err))
}

export const joinUrlPath = (...args: string[]) => {
  return args
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/[\/]*$/g, '')
      } else {
        return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
      }
    })
    .filter(item => item.length)
    .join('/')
}
