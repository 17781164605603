import { Layout } from '@ui-kitten/components'
import gql from 'graphql-tag'
import * as React from 'react'
import { StyleSheet } from 'react-native'

import { useHistory, useRouteMatch, Switch, Route } from '../Router'
import { CreatePost } from './CreatePost'
import { EditPost } from './EditPost'
import { Posts } from './Posts'

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
  },
})

export const GET_POSTS = gql`
  query GetPosts {
    myPosts {
      id
      createdAt
      updatedAt
      content
      draft
      postedFacebook
      postedInstagram
      postedLinkedin
      postedTwitter
      postTemplate {
        id
      }
    }
  }
`

export interface Post {
  content: string
}

export const PostsRouter: React.FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  return (
    <Layout style={styles.container}>
      <Switch>
        <Route exact path="/posts/new">
          <CreatePost done={() => history.push('/posts/edit')} />
        </Route>
        <Route exact path="/posts/edit">
          <EditPost done={() => history.push(path)} />
        </Route>
        <Route path={path}>
          <Posts />
        </Route>
      </Switch>
    </Layout>
  )
}
