import { AppRegistry } from 'react-native'
import * as React from 'react'
import App from 'components/src/App'

const WebWrapper = () => {
  return (
    <>
      <style type="text/css">
        {`
          @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap');
          @font-face {
            font-family: 'MaterialCommunityIcons';
            src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
          }
        `}
      </style>
      <App />
    </>
  )
}

AppRegistry.registerComponent('FGCombo', () => App)
AppRegistry.runApplication('FGCombo', {
  rootTag: document.getElementById('root'),
})
