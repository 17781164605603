import { useEffect, useState } from 'react'
import { Dimensions, ScaledSize } from 'react-native'

export const useScreenWidth = () => {
  const [width, setWidth] = useState(Dimensions.get('window').width)

  useEffect(() => {
    // width is used to determine 1 or 2 player view
    const handleDimensionChange = ({ window }: { window: ScaledSize }) => {
      setWidth(window.width)
    }
    Dimensions.addEventListener('change', handleDimensionChange)
    return () => {
      Dimensions.removeEventListener('change', handleDimensionChange)
    }
  }, [])

  return width
}

export const useMediaQueries = () => {
  const width = useScreenWidth()

  return {
    isSmallScreen: width < 644,
    isLargeScreen: 1200 <= width,
  }
}
