import {
  FACEBOOK_PRIMARY_COLOR,
  INSTAGRAM_PRIMARY_COLOR,
  LINKEDIN_PRIMARY_COLOR,
  TWITTER_PRIMARY_COLOR,
} from '../styles/colors'
import { FacebookIcon, LinkedinIcon, TwitterIcon } from '../Icons'

export const SOCIAL_MEDIA = {
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin',
  twitter: 'twitter',
}

export const SOCIAL_MEDIA_INTEGRATIONS = [SOCIAL_MEDIA.facebook]

const TITLES = {
  [SOCIAL_MEDIA.facebook]: 'Facebook',
  [SOCIAL_MEDIA.instagram]: 'Instagram',
  [SOCIAL_MEDIA.linkedin]: 'LinkedIn',
  [SOCIAL_MEDIA.twitter]: 'Twitter',
}
export const getTitle = (type: string) => TITLES[type]

const COLORS = {
  [SOCIAL_MEDIA.facebook]: FACEBOOK_PRIMARY_COLOR,
  [SOCIAL_MEDIA.instagram]: INSTAGRAM_PRIMARY_COLOR,
  [SOCIAL_MEDIA.linkedin]: LINKEDIN_PRIMARY_COLOR,
  [SOCIAL_MEDIA.twitter]: TWITTER_PRIMARY_COLOR,
}
export const getColor = (type: string) => COLORS[type]

const ICON_MAP = {
  [SOCIAL_MEDIA.facebook]: FacebookIcon,
  [SOCIAL_MEDIA.instagram]: undefined,
  [SOCIAL_MEDIA.linkedin]: LinkedinIcon,
  [SOCIAL_MEDIA.twitter]: TwitterIcon,
}
export const getIcon = (type: string) => ICON_MAP[type]
