import { Icon } from '@ui-kitten/components'
import React from 'react'
import { Image, ImageStyle } from 'react-native'
import { getImageUrl } from '../utils/image'
import { IconProps } from '@ui-kitten/components/ui/icon/icon.component'

// SM icons
export const FacebookIcon = (props: IconProps) => <Icon {...props} name="facebook" />
export const InstagramIcon = (style: ImageStyle) => (
  <Image style={style} source={{ uri: getImageUrl('static/images/instagram.png') }} />
)
export const LinkedinIcon = (props: IconProps) => <Icon {...props} name="linkedin" />
export const TwitterIcon = (props: IconProps) => <Icon {...props} name="twitter" />

export const CopyIcon = (props: IconProps) => <Icon {...props} name="copy" />
export const InfoIcon = (props: IconProps) => <Icon {...props} name="info-outline" />
export const CloseIcon = (props: IconProps) => <Icon {...props} name="close-outline" />
export const UserIcon = (props: IconProps) => <Icon {...props} name="person" />
export const EmailIcon = (props: IconProps) => <Icon {...props} name="email-outline" />
export const LockIcon = (props: IconProps) => <Icon {...props} name="lock" />
export const ChevronRightIcon = (props: IconProps) => <Icon {...props} name="chevron-right" />
export const ChevronDownIcon = (props: IconProps) => <Icon {...props} name="chevron-down" />
export const ImageIcon = (props: IconProps) => <Icon {...props} name="image-outline" />
export const PhoneIcon = (props: IconProps) => <Icon {...props} name="phone-call-outline" />
export const QuestionIcon = (props: IconProps) => <Icon {...props} name="question-mark-circle" />
export const SearchIcon = (props: IconProps) => <Icon {...props} name="search" />
