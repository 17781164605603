import { ApolloCache } from 'apollo-cache'
import { Resolvers } from 'apollo-client'
import gql from 'graphql-tag'

import { GetCurrentPost } from './__generated__/GetCurrentPost'

type ResolverFn = (parent: any, args: any, { cache }: { cache: ApolloCache<any> }) => any

interface ResolverMap {
  [field: string]: ResolverFn
}

interface AppResolvers extends Resolvers {
  Query: ResolverMap
  Mutation: ResolverMap
}

export const GET_CURRENT_POST = gql`
  query GetCurrentPost {
    currentPost @client {
      content
      draft
      postedFacebook
      postedInstagram
      postedLinkedin
      postedTwitter
      postTemplateId
      fileId
    }
  }
`

// Local state resolvers
export const resolvers: AppResolvers = {
  Query: {},
  Mutation: {
    updateCurrentPost: (_, { input }, { cache }) => {
      const currentPost = {
        __typename: 'PostInput',
        ...input,
      }

      const result = cache.readQuery<GetCurrentPost>({ query: GET_CURRENT_POST })
      if (result?.currentPost) {
        for (const key in result.currentPost) {
          if (!input[key]) {
            // @ts-ignore
            currentPost[key] = result.currentPost[key]
          }
        }
      }

      cache.writeQuery<GetCurrentPost>({
        query: GET_CURRENT_POST,
        data: {
          currentPost,
        },
      })

      // TODO save to localStorage
      return currentPost
    },
  },
}
