import url from 'url'

const { REACT_APP_API_PORT: port } = process.env

export const API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME || window.location.hostname
export const API_PORT = port === '80' || !port ? '' : port
export const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL || 'https'

// Generated
export const apiUrl = url.format({
  protocol: API_PROTOCOL,
  hostname: API_HOSTNAME,
  port: API_PORT,
})

// Constants
export const APP_NAME = 'Priffly'
export const CONTACT_EMAIL = 'support@priffly.com'
export const CONTACT_PHONE = '(512) 937-3660'
export const CONTACT_PHONE_FULL = '+15129373660'
export const LANDING_URL = 'https://priffly.com'
