import { Button, ButtonProps } from '@ui-kitten/components'
import * as React from 'react'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  button: {
    marginVertical: 30,
  },
})

interface Props extends ButtonProps {
  title?: string
}

export const ConfirmButton: React.FC<Props> = ({ title, ...rest }) => {
  return (
    <Button
      status="success"
      style={styles.button}
      size="large"
      {...rest}
    >
      {title ?? 'OK'}
    </Button>
  )
}
