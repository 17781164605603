import React from 'react'

import { CONTACT_PHONE, CONTACT_PHONE_FULL } from '../config'
import { ExternalLink } from '../Components/ExternalLink'
import { contactStyles } from './contact'

export interface ContactPhoneProps {
  isButton?: boolean
}

export const ContactSMS: React.FC<ContactPhoneProps> = ({ isButton = false }) => {
  return (
    <ExternalLink
      style={contactStyles.button}
      href={`sms:+${CONTACT_PHONE_FULL}`}
      icon="message-circle-outline"
      isButton={isButton}
    >
      {CONTACT_PHONE}
    </ExternalLink>
  )
}
