import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Card, Input, Layout, Text, Tooltip } from '@ui-kitten/components'
import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import * as LogOutTypes from './__generated__/LogOut'
import { GET_CURRENT_USER, LOG_OUT_USER } from './gql'
import Loading from '../Loading'
import useCurrentUser from '../state/useCurrentUser'
import { GetCurrentUser } from './__generated__/GetCurrentUser'
import ErrorDisplay from '../Notifications/ErrorDisplay'
import { InfoIcon } from '../Icons'
import { NotificationsHub } from '../Notifications/NotificationsHub'

const useInputChanges = (initialValue?: string | null) => {
  const [value, setValue] = React.useState(initialValue ?? '')
  return {
    value,
    onChangeText: setValue,
  }
}

export const UserProfile = () => {
  const client = useApolloClient()
  const { clearUser } = useCurrentUser()
  const { data, loading: loadingQuery } = useQuery<GetCurrentUser>(GET_CURRENT_USER, {
    onError() {
      // Top level query should catch if user is not logged in
    },
  })
  const [logout, { error, loading: loadingLogout }] = useMutation<LogOutTypes.LogOut>(LOG_OUT_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onCompleted() {
      clearUser()
    },
  })

  const emailInputChanges = useInputChanges(data?.currentUser?.email || data?.currentUser?.facebookEmail)
  const passwordInputChanges = useInputChanges()
  const [tooltipVisible, setTooltipVisible] = React.useState(false)

  const onLogout = () => {
    return logout()
      .then(() => client.resetStore())
      .catch(() => {
        // Ignore
      })
  }
  const loading = loadingLogout || loadingQuery
  if (loading) {
    return <Loading splash />
  }

  if (!data?.currentUser) {
    return null
  }

  const Footer = () => (
    <View style={styles.actions}>
      {/*<Button*/}
      {/*  style={styles.action}*/}
      {/*  disabled={loading || [emailInputChanges.value, passwordInputChanges.value].some(v => !v)}*/}
      {/*  appearance="filled"*/}
      {/*  onPress={() => alert('TODO')}*/}
      {/*>*/}
      {/*  Save*/}
      {/*</Button>*/}
      <Button style={styles.action} disabled={loading} appearance="outline" onPress={onLogout}>
        Log Out
      </Button>
      <ErrorDisplay error={error} />
    </View>
  )

  return (
    <Layout style={styles.container}>
      <NotificationsHub />
      <Layout style={styles.card}>
        <Layout style={styles.body}>
          {renderRow('Email', emailInputChanges.value)}
          {renderRow('Name', data.currentUser.name || data.currentUser.facebookName)}
          {renderRow('Logged in with', data.currentUser.facebookName && 'Facebook')}
          <Layout style={styles.row}>
            <Text style={{ width: 150, fontWeight: 'bold' }}>Plan</Text>
            <View style={styles.value}>
              <Text style={{ marginRight: 10 }}>$0 / month</Text>
              <Tooltip
                visible={tooltipVisible}
                text="Priffly is currently free because of COVID-19"
                placement="bottom end"
                onBackdropPress={() => setTooltipVisible(false)}
              >
                <TouchableOpacity
                  onPress={() => {
                    setTooltipVisible(true)
                  }}
                >
                  <InfoIcon width={20} height={20} />
                </TouchableOpacity>
              </Tooltip>
            </View>
          </Layout>
          {/*<Input disabled={true} label="email" style={styles.input} {...emailInputChanges} />*/}
          {/*<Input disabled={true} label="password" style={styles.input} {...passwordInputChanges} secureTextEntry />*/}
        </Layout>
        <Layout style={styles.footer}>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  )
}

const renderRow = (key: string, value: string | null) =>
  value && (
    <Layout style={styles.row}>
      <Text style={{ flex: 1, width: 150, fontWeight: 'bold' }}>{key}</Text>
      <Text>{value}</Text>
    </Layout>
  )

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  value: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  card: {
    height: 250,
    justifyContent: 'flex-start',
  },
  body: {},
  footer: {
    marginVertical: 20,
  },
  row: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  input: {
    marginVertical: 10,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 20,
  },
  action: {
    minWidth: 100,
    marginRight: 20,
  },
})
