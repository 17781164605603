import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Card, Layout, Modal, Text } from '@ui-kitten/components'
import * as clipboard from 'clipboard-polyfill'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import ErrorDisplay from '../Notifications/ErrorDisplay'
import { CloseIcon, CopyIcon } from '../Icons'
import Loading from '../Loading'
import { GetPost } from './__generated__/GetPost'
import { FADED_GREY, GREY } from '../styles/colors'
import { FacebookShareButton } from '../SocialMedia/FacebookShareButton'
import { PORTRAIT_WIDTH } from '../styles'
import { UPDATE_POST } from './gql'
import * as UpdatePostTypes from './__generated__/UpdatePost'
import { GET_POSTS } from './index'

const GET_POST = gql`
  query GetPost($id: ID!) {
    post(id: $id) {
      content
      draft
      postedFacebook
      postedInstagram
      postedLinkedin
      postedTwitter
      postTemplate {
        content
      }
    }
  }
`

interface Props {
  id: string
  done: () => void
}

export const ShareFacebookPost: React.FC<Props> = ({ id, done }) => {
  const { data, error, loading } = useQuery<GetPost>(GET_POST, {
    variables: { id },
    onError() {},
  })
  const [updatePost, { error: errorMutation }] = useMutation<
    UpdatePostTypes.UpdatePost,
    UpdatePostTypes.UpdatePostVariables
  >(UPDATE_POST, {
    refetchQueries: [{ query: GET_POSTS }],
  })

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  const onPress = () => {
    clipboard.writeText(data?.post?.content ?? '')
    setCopied(() => true)
  }
  const onShare = () =>
    updatePost({
      variables: {
        id,
        input: {
          draft: false,
          postedFacebook: true,
        },
      },
    })
      .catch(err => console.warn(err))
      .then(done)

  return (
    <Modal visible={true} backdropStyle={styles.backdrop} onBackdropPress={() => done()}>
      <Layout style={styles.modalContainer}>
        <Button style={styles.button} appearance="ghost" status="basic" onPress={done} icon={CloseIcon} />
        <Loading loading={loading}>
          <Text style={styles.instruction}>1. {Platform.OS === 'web' ? 'Click' : 'Tap'} and copy your post</Text>
          <Card style={styles.card} onPress={onPress}>
            <Text>{data?.post?.content}</Text>
          </Card>
          <Layout style={styles.actions}>
            <Button style={styles.button} icon={CopyIcon} onPress={onPress} />
            <Text style={styles.copied}>{copied ? 'Copied!' : ''}</Text>
          </Layout>
          <Text style={styles.instruction}>2. Go to Facebook and paste your post</Text>
          <Text style={styles.instructionDetail}>If you are managing a Page</Text>
          <FacebookShareButton
            disabled={!copied}
            style={styles.button}
            url="https://www.facebook.com/pages/?category=your_pages"
            title="Go to Facebook Pages"
            onPress={onShare}
          />
          <Text style={styles.instructionDetail}>Or post to your personal account</Text>
          <FacebookShareButton
            disabled={!copied}
            style={styles.button}
            url="http://facebook.com"
            title="Go to Facebook"
            onPress={onShare}
          />
        </Loading>
        <ErrorDisplay error={error} />
        <ErrorDisplay error={errorMutation} />
      </Layout>
    </Modal>
  )
}

const styles = StyleSheet.create({
  instruction: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 22,
  },
  instructionDetail: {
    marginTop: 20,
    marginBottom: 8,
  },
  copied: {
    marginLeft: 15,
    color: GREY,
    fontWeight: 'bold',
    height: 30,
  },
  backdrop: {
    backgroundColor: FADED_GREY,
  },
  container: {
    minHeight: PORTRAIT_WIDTH * 0.5,
    padding: 16,
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: PORTRAIT_WIDTH,
    padding: 16,
  },
  actions: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 10,
    alignItems: 'center',
  },
  button: {},
  card: {
    minHeight: 100,
    width: '100%',
    marginTop: 20,
  },
})
