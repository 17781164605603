// TODO move these into db?
import { getStored, setStored } from '../state/clientStorage'
import { useEffect, useState } from 'react'

interface Notification {
  text: string
}

const NOTIFICATIONS: Notification[] = [
  {
    text: `Because of COVID-19, we decided to give everyone free service 1 year, after which you still get 50% off forever.`,
  },
]

const NOTIFICATION_KEY = 'tmp_notifs'

export const useNotifications = () => {
  const [unseen, setUnseen] = useState<Notification[]>([])

  useEffect(() => {
    let seen: string[]
    try {
      seen = JSON.parse(getStored(NOTIFICATION_KEY) || '[]')
    } catch {
      seen = []
    }

    setUnseen(NOTIFICATIONS.filter(n => !seen.includes(n.text)))
  }, [])

  // TODO time-based, or toggle based, or what?
  const confirm = () => {
    setUnseen([])
    setStored(NOTIFICATION_KEY, JSON.stringify(NOTIFICATIONS.map(n => n.text)))
  }
  return {
    unseen,
    confirm,
  }
}
