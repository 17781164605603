import { Button, Layout, Text } from '@ui-kitten/components'
import * as React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { useFreshUser } from './state/useSetupSM'
import { FACEBOOK_PRIMARY_COLOR, FADED_GREY, TWITTER_PRIMARY_COLOR } from './styles/colors'
import { Posts } from './Posts/Posts'
import { ExternalLink } from './Components/ExternalLink'
import { Photos } from './Photos/Photos'
import { useHistory } from './Router'
import { Heading } from './Components/Heading'

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  card: {
    borderColor: FADED_GREY,
    borderWidth: 1,
    borderRadius: 10,
    padding: 20,
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
  },
  passage: {
    marginTop: 20,
    marginBottom: 10,
  },
  actions: {
    marginVertical: 20,
  },
  socialMedia: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
})

export const Home: React.FC = () => {
  const history = useHistory()
  const { isFreshUser, toggleOffFreshUser } = useFreshUser()

  if (isFreshUser) {
    return (
      <Layout>
        <Text category="h3">Setup Social Media Accounts</Text>

        <Layout style={styles.passage}>
          <Text>If you do not yet have social media accounts for you or your business, please set those up now.</Text>
        </Layout>

        <Layout style={styles.passage}>
          <Text>
            For Facebook, we recommend setting up a specific Facebook Page for your business and inviting people you
            know.{' '}
          </Text>
        </Layout>
        <ExternalLink
          isButton
          color={FACEBOOK_PRIMARY_COLOR}
          icon="facebook"
          href="https://www.facebook.com/pages/creation"
          style={{ width: 280, marginBottom: 5 }}
        >
          Setup Facebook Page
        </ExternalLink>

        <Layout style={styles.passage}>
          <Text>For Twitter, we recommend setting up a specific non-personal account for your business.</Text>
        </Layout>
        <ExternalLink
          isButton
          color={TWITTER_PRIMARY_COLOR}
          style={{ width: 280 }}
          href="https://twitter.com"
          icon="twitter"
        >
          Setup Twitter
        </ExternalLink>

        <Layout style={styles.actions}>
          <Layout style={styles.passage}>
            <Text>Are your accounts ready to go?</Text>
          </Layout>
          <Button
            status="success"
            style={{ width: 280 }}
            onPress={() => {
              toggleOffFreshUser()
            }}
          >
            Yes, I'm all set
          </Button>
        </Layout>
      </Layout>
    )
  }

  return (
    <Layout style={styles.container}>
      <Layout style={styles.card}>
        <Heading onPress={() => history.push('/posts')} title="Prepared Posts" />
        <Posts hideHeading limit={3} />
      </Layout>
      <Layout style={styles.card}>
        <Heading onPress={() => history.push('/album')} title="Photo Album" />
        <Photos limit={3} />
      </Layout>
    </Layout>
  )
}
