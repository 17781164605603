import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { Dimensions } from './types'

interface Props {
  index?: number
  photo?: File
  setPhoto: (file: File) => any
  setDimensions: (dimensions: Dimensions) => any
}

// to get the actual image size, we need to create a new Image https://stackoverflow.com/questions/10478649/get-actual-image-size-after-resizing-it
function getImageDimensions(path: string, callback: (dimensions: Dimensions) => any) {
  const img = new Image()
  img.onload = function() {
    callback({
      width: img.width,
      height: img.height,
    })
  }
  img.src = path
}

export const PhotoUploader: React.FC<Props> = ({ photo, setPhoto, setDimensions, index = 0 }) => {
  const elementId = `uploaded-photo${index}`

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file: File, index: number) => {
      /* Sample file:
lastModified: 1589340879873
lastModifiedDate: new Date('Tue May 12 2020 22:34:39 GMT-0500 (Central Daylight Time)')
name: "phone1.png"
path: "phone1.png"
size: 162919
type: "image/png"
webkitRelativePath: ""
      */
      if (index === 0) {
        const reader = new FileReader()
        reader.onabort = () => {
          console.info('file reading was aborted')
          // TODO display error
        }
        reader.onerror = () => {
          console.info('file reading has failed')
          // TODO display error
        }
        reader.onload = () => {
          const element = document.getElementById(elementId) as HTMLImageElement
          const src = reader.result as string
          element.src = src

          getImageDimensions(src, setDimensions)
        }
        reader.readAsDataURL(file)
        setPhoto(file)
      }
    })
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      <div {...getRootProps()} className="photo-uploader">
        <input {...getInputProps({ multiple: false, required: true })} />
        <img id={elementId} alt="" className="uploaded-photo" />
        <p>Drag & drop or click here to {photo ? 'select another' : 'select'} photo</p>
      </div>
    </>
  )
}
