import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GetPosts } from './__generated__/GetPosts'
import { GET_POSTS } from './index'
import * as UpdatePostTypes from './__generated__/UpdatePost'
import { UPDATE_POST } from './gql'
import { Button, Card, CardHeader, Layout, Text } from '@ui-kitten/components'
import ErrorDisplay from '../Notifications/ErrorDisplay'
import sortBy from 'lodash/sortBy'
import { formatUrlQuery } from '../Router/query'
import moment from 'moment'
import { FacebookShareButton } from '../SocialMedia/FacebookShareButton'
import { TwitterShareButton } from '../SocialMedia/TwitterShareButton'
import { ShareFacebookPost } from './ShareFacebookPost'
import Loading from '../Loading'
import { ConfirmButton } from '../UI/ConfirmButton'
import { useHistory } from '../Router'
import { StyleSheet } from 'react-native'
import { PORTRAIT_WIDTH } from '../styles'
import { Heading } from '../Components/Heading'

interface Props {
  hideHeading?: boolean
  limit?: number
}

export const Posts: React.FC<Props> = ({ hideHeading = false, limit }) => {
  const history = useHistory()
  const [updatePost] = useMutation<UpdatePostTypes.UpdatePost, UpdatePostTypes.UpdatePostVariables>(UPDATE_POST, {
    refetchQueries: [{ query: GET_POSTS }],
    onError() {},
  })
  const { data, error, loading } = useQuery<GetPosts>(GET_POSTS)
  const [shareId, setShareId] = useState<string>()

  const onPrepare = () => history.push('/posts/new')
  const prepareButton = <ConfirmButton title="Prepare Post" onPress={onPrepare} />

  return (
    <Loading loading={loading}>
      {!data?.myPosts?.length ? (
        <Layout style={styles.container}>
          <ErrorDisplay error={error} />
          <Text style={styles.text}>Posting once a day is key to maintain a social media presence</Text>
          {prepareButton}
        </Layout>
      ) : (
        <Layout style={styles.container}>
          {hideHeading || (
            <Heading title="My Posts">
              <Button status="success" onPress={() => history.push('/posts/new')}>
                Prepare Post
              </Button>
            </Heading>
          )}
          <Text style={[styles.text, styles.instruction]}>
            Share your prepared post to social media platforms. We recommend sharing 1 per day, so online visitors know
            that you are active.
          </Text>
          <Layout style={styles.posts}>
            {sortBy(
              data?.myPosts,
              ({ postedFacebook, postedTwitter }) => {
                return postedFacebook && postedTwitter
              },
              ({ updatedAt }) => -new Date(updatedAt).getTime(),
            )
              .map(post => {
                const pathname = 'https://twitter.com/intent/tweet'
                const queryString = formatUrlQuery({
                  text: post.content,
                })
                const twitterHref = `${pathname}?${queryString}`
                const postedAlready = post.postedFacebook && post.postedTwitter
                const callToAction = postedAlready ? '' : 'Post to'
                const Header = () => (
                  <CardHeader title="" style={styles.cardHeader}>
                    <Layout style={styles.postBody}>
                      <Text style={styles.content}>{post.content}</Text>
                      <Text style={styles.timing}>
                        {postedAlready ? `Posted ${moment(post.updatedAt).fromNow()}` : 'Ready to post'}
                      </Text>
                    </Layout>
                  </CardHeader>
                )
                return (
                  <Card style={styles.card} key={post.id} header={Header} status={postedAlready ? 'basic' : 'primary'}>
                    <Layout style={styles.footer}>
                      <Text>{callToAction}</Text>
                      <Layout style={[styles.right, { marginLeft: 10 }]}>
                        <FacebookShareButton
                          posted={post.postedFacebook}
                          iconOnly
                          onPress={() => setShareId(post.id)}
                        />
                        {/*<InstagramShareButton iconOnly onPress={() => setShareId(post.id)} />*/}
                        <TwitterShareButton
                          posted={post.postedTwitter}
                          iconOnly
                          url={twitterHref}
                          onPress={() => {
                            updatePost({
                              variables: {
                                id: post.id,
                                input: {
                                  postedTwitter: true,
                                },
                              },
                            })
                          }}
                        />
                      </Layout>
                    </Layout>
                  </Card>
                )
              })
              .slice(0, limit)}
          </Layout>
        </Layout>
      )}
      {shareId && <ShareFacebookPost id={shareId} done={() => setShareId('')} />}
    </Loading>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
  },
  posts: {
    alignSelf: 'stretch',
    maxWidth: PORTRAIT_WIDTH * 3.3,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  card: {
    // @ts-ignore
    cursor: 'default',
    flex: 1,
    flexBasis: PORTRAIT_WIDTH,
    maxWidth: PORTRAIT_WIDTH,
    margin: 5,
    alignSelf: 'stretch',
  },
  cardHeader: {
    // @ts-ignore
    cursor: 'default',
  },
  postBody: {
    flexGrow: 1,
    width: '100%',
    minHeight: PORTRAIT_WIDTH * 0.25,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  header: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  footer: {
    flexGrow: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  right: {
    marginLeft: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  text: {
    marginTop: 20,
    marginBottom: 10,
  },
  instruction: {
    alignSelf: 'flex-start',
    marginBottom: 30,
  },
  button: {
    marginVertical: 30,
  },
  content: {
    textAlign: 'justify',
  },
  timing: {
    fontSize: 10,
    alignSelf: 'flex-end',
  },
})
