import gql from 'graphql-tag'

export const initializePostInput = (initial?: { content?: string; postTemplateId?: string; fileId?: string }) => {
  const { content, postTemplateId, fileId } = initial ?? {}
  return {
    draft: true,
    postedFacebook: false,
    postedInstagram: false,
    postedLinkedin: false,
    postedTwitter: false,
    content: content ?? '',
    postTemplateId: postTemplateId ?? '',
    fileId: fileId ?? '',
  }
}

export const UPDATE_CURRENT_POST = gql`
  mutation UpdateCurrentPost($input: PostInput!) {
    updateCurrentPost(input: $input) @client {
      postTemplateId
    }
  }
`

export const UPDATE_POST = gql`
  mutation UpdatePost($id: ID!, $input: PostInput!) {
    updatePost(id: $id, input: $input) {
      createdAt
      updatedAt
      content
      draft
      postedFacebook
      postedInstagram
      postedLinkedin
      postedTwitter
    }
  }
`
