import { Button, Layout, Text } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet } from 'react-native'

interface Props {
  title: string
  actionText?: string
  onPress?: () => any
}

export const Heading: React.FC<Props> = ({ children, title, actionText = 'View All', onPress }) => {
  return (
    <Layout style={styles.headingRow}>
      <Layout>
        <Text category="h3">{title}</Text>
      </Layout>
      <Layout>
        {children
          ? children
          : onPress && (
              <Button status="basic" appearance="ghost" onPress={onPress}>
                {actionText}
              </Button>
            )}
      </Layout>
    </Layout>
  )
}

const styles = StyleSheet.create({
  headingRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%',
  },
})
