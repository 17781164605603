import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Input, Layout, Text } from '@ui-kitten/components'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import { GET_CURRENT_POST } from '../state/resolvers'
import { GetCurrentPost } from '../state/__generated__/GetCurrentPost'
import { ConfirmButton } from '../UI/ConfirmButton'
import Loading from '../Loading'
import ErrorDisplay from '../Notifications/ErrorDisplay'
import { PORTRAIT_WIDTH } from '../styles'
import { CreatePost, CreatePostVariables } from './__generated__/CreatePost'
import GlobalError from '../Notifications/GlobalError'
import { GET_POSTS } from './index'
import { Photo } from '../Photos/Photo'
import { ImageIcon } from '../Icons'
import { useHistory } from '../Router'

const CREATE_POST = gql`
  mutation CreatePost($input: PostInput) {
    createPost(input: $input) {
      id
    }
  }
`

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  input: {
    width: PORTRAIT_WIDTH,
  },
  passage: {
    marginTop: 10,
  },
  characterCounter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  photo: {
    marginTop: 20,
  },
  photoButton: {
    marginTop: 20,
  },
})

interface Props {
  done: () => void
}

const BLANK_REGEX = /\[[^\]]+\]/g

const POST_CHARACTER_LIMIT = 280
const characterLimitText = ` / ${POST_CHARACTER_LIMIT} characters`

export const EditPost: React.FC<Props> = ({ done }) => {
  const history = useHistory()
  const { data, error, loading } = useQuery<GetCurrentPost>(GET_CURRENT_POST)
  const [mutate, { loading: loadingMutation }] = useMutation<CreatePost, CreatePostVariables>(CREATE_POST, {
    errorPolicy: 'all',
    refetchQueries: [{ query: GET_POSTS }],
    onError() {},
    onCompleted() {
      done()
    },
  })

  const [content, setContent] = useState(data?.currentPost?.content ?? '')
  useEffect(() => {
    if (data?.currentPost?.content) {
      setContent(data.currentPost.content)
    }
  }, [data?.currentPost?.content])

  const [blanks, setBlanks] = useState([] as string[])
  useEffect(() => {
    // For any [fill in the blank] substring in the template, we need to prevent saving
    const tmp = content.match(BLANK_REGEX)
    setBlanks(tmp ?? [])
  }, [content])

  if (loading) {
    return <Loading />
  }

  const onPress = () => {
    const input = {
      ...data?.currentPost,
      __typename: undefined,
      content,
    }
    return mutate({ variables: { input } })
  }

  const overLimit = content.length > POST_CHARACTER_LIMIT

  return (
    <Layout style={styles.container}>
      <ErrorDisplay error={error} />
      <GlobalError />
      <Input
        label="Customize Your Post"
        style={styles.input}
        autoFocus
        numberOfLines={8}
        multiline
        onChangeText={setContent}
        textAlignVertical="top"
        value={content}
      />
      <Layout style={styles.characterCounter}>
        <Text category="label" status={overLimit ? 'danger' : 'basic'}>
          {`${content.length}`}
        </Text>
        <Text category="label">{characterLimitText}</Text>
      </Layout>
      {overLimit && (
        <Text style={{ alignSelf: 'flex-end' }} status="danger" category="label">
          Text is too long
        </Text>
      )}
      {!!blanks.length && (
        <Text style={styles.passage} status="warning">
          Please replace the following with your own text:
        </Text>
      )}
      {blanks.map((blank, index) => {
        return (
          <Text status="warning" category="label">
            {blank}
          </Text>
        )
      })}
      {data?.currentPost?.fileId && (
        <Layout style={styles.photo}>
          <Photo id={data.currentPost.fileId} width={PORTRAIT_WIDTH} />
        </Layout>
      )}
      <Button
        style={styles.photoButton}
        appearance="outline"
        status="primary"
        icon={ImageIcon}
        onPress={() => {
          history.push('/album', {
            returnPath: '/posts/edit',
          })
        }}
      >
        {data?.currentPost?.fileId ? 'Replace Photo' : 'Add Photo'}
      </Button>
      <ConfirmButton
        disabled={overLimit || !content || loadingMutation || !!blanks.length}
        title="Save"
        onPress={onPress}
      />
    </Layout>
  )
}
