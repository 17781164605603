import { ApolloProvider, useQuery } from '@apollo/react-hooks'
import { mapping, light as lightTheme } from '@eva-design/eva'
import { ApplicationProvider, Avatar, IconRegistry, Text } from '@ui-kitten/components'
import { EvaIconsPack } from '@ui-kitten/eva-icons'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'

import NotFound from './Navigation/404'
import { NavigationBar } from './Navigation/NavigationBar'
import { Redirect, Route, Router, Switch } from './Router'
import { RouterContextProvider } from './Router/context'
import { resolvers } from './state/resolvers'
import { typeDefs } from './state/schema'
import { apiUrl, APP_NAME, LANDING_URL } from './config'
import Login from './Users/LoginPage'
import { GET_CURRENT_USER, GET_LOGGED_IN_USER } from './Users/gql'
import Loading from './Loading'
import useCurrentUser, { loadUser } from './state/useCurrentUser'
import { GetCurrentUser } from './Users/__generated__/GetCurrentUser'
import { GetLoggedInUser } from './Users/__generated__/GetLoggedInUser'
import { useBackButton } from './utils/useBackButton'
import { default as customMapping } from './custom-mapping.json'
import { default as appTheme } from './custom-theme.json'
import { PostsRouter } from './Posts'
import { UserProfile } from './Users/UserProfile'
import { processErrors, useNetworkStateFromUrlParam } from './state/useNetwork'
import GlobalError from './Notifications/GlobalError'
import { NetworkMessage } from './state/__generated__/NetworkMessage'
import { identify, initializeSessionReplay } from './dev/sessionReplay'
import { Logo } from './Navigation/Logo'
import { PRIMARY_COLOR } from './styles/colors'
import { Home } from './Home'
import { openExternalLink } from './common/linking'
import { LiveChat } from './dev/LiveChat'
import { Album } from './Photos/Album'

const theme = { ...lightTheme, ...appTheme }

// set up monitoring
initializeSessionReplay()

///////////////////////////////////////////////////////////////////////////////////////////////////
// setup Apollo client
///////////////////////////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line prefer-const
let client: ApolloClient<NormalizedCacheObject>
const cache = new InMemoryCache()

const httpLink = createUploadLink({
  credentials: 'include',
  uri: `${apiUrl}/graphql`,
  fetchOptions: {
    credentials: 'include',
  },
})

const errorLink = onError(errorResponse => {
  const err = processErrors(errorResponse)
  let data = {}
  if (
    // @ts-ignore
    errorResponse?.networkError?.statusCode === 401 ||
    errorResponse?.graphQLErrors?.some(e => e.extensions?.code === 'UNAUTHENTICATED')
  ) {
    data = {
      currentUser: null,
    }
  }

  if (err.currentNetworkState.message && 'GetLoggedInUser' !== errorResponse.operation.operationName) {
    data = {
      ...data,
      ...err,
    }
  }
  if (Object.keys(data).length) {
    client.writeData({ data })
  }
})

const successLink = new ApolloLink((operation, forward) => {
  let success = true
  const out = forward(operation).map(response => {
    success = success && !response.errors
    return response
  })
  if (success) {
    client.writeData<NetworkMessage>({
      data: processErrors(),
    })
  }
  return out
})

client = new ApolloClient({
  cache,
  link: successLink.concat(errorLink.concat(httpLink)),
  resolvers,
  typeDefs,
})

const data = ([loadUser] as (() => any)[]).reduce(
  (previousValue, currentValue) => {
    return {
      ...previousValue,
      ...currentValue(),
    }
  },
  {
    currentPost: null,
    currentNetworkState: processErrors(),
  },
)
cache.writeData({
  data,
})

///////////////////////////////////////////////////////////////////////////////////////////////////
// setup App
///////////////////////////////////////////////////////////////////////////////////////////////////
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 12,
    paddingHorizontal: 16,
    paddingBottom: 64,
    alignItems: 'center',
  },
  header: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  whatIs: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  whatIsText: {
    color: PRIMARY_COLOR,
    fontSize: 14,
  },
  whatIsAction: {
    fontWeight: '600',
  },
})

const Body = () => {
  useBackButton()

  return (
    <View style={styles.container}>
      <GlobalError />
      <Switch>
        {/*<Redirect exact from="/" to="/posts" />*/}
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/posts">
          <PostsRouter />
        </Route>
        <Route exact path="/me">
          <UserProfile />
        </Route>
        <Route path="/album">
          <Album />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </View>
  )
}

const App = () => (
  <RouterContextProvider>
    <ScrollView>
      <Helmet>
        <title>{APP_NAME}</title>
      </Helmet>
      <Router>
        <NavigationBar />
        <Body />
      </Router>
    </ScrollView>
  </RouterContextProvider>
)

function IsLoggedIn() {
  useNetworkStateFromUrlParam()
  const { clearUser, setUser } = useCurrentUser()
  const { data: userData, loading } = useQuery<GetLoggedInUser>(GET_LOGGED_IN_USER, {
    onCompleted(getCurrentUser) {
      if (getCurrentUser.me) {
        identify({
          id: getCurrentUser.me.id,
          email: getCurrentUser.me.email,
          name: getCurrentUser.me.name,
          username: getCurrentUser.me.username,
          facebookEmail: getCurrentUser.me.facebookEmail,
          facebookShortName: getCurrentUser.me.facebookShortName,
          facebookName: getCurrentUser.me.facebookName,
        })
        setUser({
          email: getCurrentUser.me.email,
          name: getCurrentUser.me.name,
          username: getCurrentUser.me.username,
          facebookEmail: getCurrentUser.me.facebookEmail,
          facebookShortName: getCurrentUser.me.facebookShortName,
          facebookName: getCurrentUser.me.facebookName,
        })
      }
    },
    onError() {
      clearUser()
    },
    errorPolicy: 'all',
  })

  const { data, loading: cacheLoading } = useQuery<GetCurrentUser>(GET_CURRENT_USER, {
    onError() {
      // Nothing
    },
  })

  if (loading || cacheLoading) {
    return <Loading />
  }
  if (data?.currentUser) {
    return (
      <>
        <App />
        <LiveChat userId={userData?.me?.id ?? ''} email={userData?.me?.email ?? userData?.me?.facebookEmail ?? ''} />
      </>
    )
  }
  return (
    <View>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => openExternalLink(LANDING_URL)}>
          <Logo large />
        </TouchableOpacity>
      </View>
      <View style={styles.whatIs}>
        <TouchableOpacity onPress={() => openExternalLink(LANDING_URL)}>
          <Text style={[styles.whatIsText, styles.whatIsAction]}>What is Priffly?</Text>
        </TouchableOpacity>
      </View>
      <Login />
    </View>
  )
}

export default () => {
  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      {/*
  // @ts-ignore */}
      <ApplicationProvider mapping={mapping} customMapping={customMapping} theme={theme}>
        <ApolloProvider client={client}>
          <IsLoggedIn />
        </ApolloProvider>
      </ApplicationProvider>
    </>
  )
}
