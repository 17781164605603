import { useMutation } from '@apollo/react-hooks'
import { Input } from '@ui-kitten/components'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { StyleSheet, Text } from 'react-native'

import { PhotoUploader } from './PhotoUploader'
import * as UploadPhoto from './__generated__/UploadPhoto'
import { ConfirmButton } from '../UI/ConfirmButton'
import { Dimensions } from './types'
import { GET_FILES } from './gql'

const UPLOAD_PHOTO = gql`
  mutation UploadPhoto($input: FileInput!) {
    uploadFile(input: $input) {
      id
      createdAt
      updatedAt
      name
      location
    }
  }
`

interface Props {
  done: () => void
}

export const CreatePhoto: React.FC<Props> = ({ done }) => {
  const [mutate, { loading }] = useMutation<UploadPhoto.UploadPhoto, UploadPhoto.UploadPhotoVariables>(UPLOAD_PHOTO, {
    refetchQueries: [{ query: GET_FILES }],
  })

  const [name, setName] = useState('')
  const [photo, setPhoto] = useState<File>()
  const [dimensions, setDimensions] = useState<Dimensions>({ width: undefined, height: undefined })

  const submit = () => {
    mutate({
      variables: {
        input: {
          file: photo,
          name,
          width: dimensions.width,
          height: dimensions.height,
        },
      },
    })
      .then(() => {
        done()
      })
      .catch(console.error)
  }

  const imageResolution =
    dimensions.width && dimensions.height ? (
      <Text>
        {dimensions.width}x{dimensions.height}
      </Text>
    ) : null

  return (
    <>
      <PhotoUploader setDimensions={setDimensions} photo={photo} setPhoto={setPhoto} />
      {imageResolution}
      <Input label="Name (optional)" style={styles.nameField} autoFocus={true} onChangeText={setName} value={name} />
      <ConfirmButton title="Upload" onPress={submit} disabled={!photo || loading} />
    </>
  )
}

const styles = StyleSheet.create({
  nameField: {},
})
