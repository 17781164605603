import React from 'react'
import { Image, Platform, StyleSheet, Text, View } from 'react-native'
import { Button } from '@ui-kitten/components'
import { Link } from '../Router'
import { getImageUrl } from '../utils/image'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  notFound: {
    fontSize: 32,
    margin: 32,
    textAlign: 'center',
  },
  image: {
    width: 200,
    height: 200,
  },
})

const NotFound: React.FC = () => (
  <View style={styles.container}>
    <Text style={styles.notFound}>Page Not Found</Text>
    <Link to="/">
      <Button>Go Home</Button>
    </Link>
  </View>
)

export default NotFound
