import * as React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'

interface Props {
  children?: any
  loading?: boolean
  splash?: boolean
}

export const styles = StyleSheet.create({
  spinner: {
    margin: 16,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  splash: {
    // Hack the height to hide other elements off-screen
    height: '110%',
    width: '100%',
  },
})

const Loading: React.FC<Props> = ({ children, loading = true, splash = false }) => {
  if (loading) {
    const body = (
      <View style={styles.spinner}>
        <ActivityIndicator size="large" />
      </View>
    )
    if (splash) {
      return <View style={styles.splash}>{body}</View>
    }
    return body
  }

  return children ?? null
}

export default Loading
