import gql from 'graphql-tag'

export const GET_FILE = gql`
  query GetFile($id: ID!) {
    file(id: $id) {
      id
      createdAt
      updatedAt
      width
      height
      location
    }
  }
`

export const GET_FILES = gql`
  query GetFiles($limit: Int) {
    myFiles(limit: $limit) {
      id
      createdAt
      updatedAt
      name
      location
    }
  }
`

export const DELETE_PHOTO = gql`
  mutation DeletePhoto($id: ID!) {
    deleteFile(id: $id) {
      id
      location
      name
    }
  }
`
