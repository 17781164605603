import { useQuery } from '@apollo/react-hooks'
import { Layout } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { GET_FILE } from './gql'
import Loading from '../Loading'
import { Image } from 'react-native'
import * as GetFile from './__generated__/GetFile'

interface Props {
  id: string
  width?: number
}

export const Photo: React.FC<Props> = ({ id, width = 200 }) => {
  const { data, loading } = useQuery<GetFile.GetFile, GetFile.GetFileVariables>(GET_FILE, {
    variables: {
      id,
    },
    onError() {
      // Ignore error
    },
  })

  if (loading) {
    return <Loading />
  }

  if (!data?.file?.location) {
    return null
  }

  const height = data.file.width && data.file.height ? (width / data.file.width) * data.file.height : 200

  return (
    <Layout>
      <Image style={[styles.image, { width, height }]} source={{ uri: data.file.location }} />
    </Layout>
  )
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
  },
})
